










































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { BCard, BAvatar, BPagination, BCardText, BRow, BContainer, BCol, BFormCheckbox, BButton, BSpinner, BFormSelect, BFormSelectOption, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend } from 'bootstrap-vue';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { UtilisateurTrombinoscope } from '../../api/models/utilisateurs/utilisateur';
import { Fonction } from '../../api/models/authorization/fonctions/fonction';

@Component({
  components: {
    BCard,
    BAvatar,
    BCardText,
    BRow,
    BPagination,
    BContainer,
    BCol,
    BFormCheckbox,
    BButton,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
  }
})

export default class Trombinoscope extends Vue {
  loading: boolean = true;
  currentPage = 1

  listFonctions = new PaginatedList<Fonction>()
  searchQuery: string | null = null;
  searchBy: string = "name";
  perPage = 10;
  isSortDirDesc = false
  sortBy='nom'
  perPageOptions = [2, 5, 10, 25, 50];

  paginatedListUtilisateursTrombinoscope = new PaginatedList<UtilisateurTrombinoscope>()

  utilisateurFullName(user: UtilisateurTrombinoscope) {
    return `${user.nom} ${user.prenom}`;
  }

  async created() {
    await this.loadFonctions();
    await this.loadTrombinoscope({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      searchBy: this.searchBy
    });
  }

  @Watch("searchBy") searchByChanged() {
    this.searchQuery = "";
  }

  get paginationFrom() {
    return this.perPage * (this.currentPage-1) + (this.paginatedListUtilisateursTrombinoscope.items.length ? 1 : 0)
  }
  get paginationTo() {
    return this.perPage * (this.currentPage -1) + this.paginatedListUtilisateursTrombinoscope.items.length
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadTrombinoscope({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      searchBy: this.searchBy,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc
    });
  }

  async searchUtilisateurs() {
    this.loading = true;
    setTimeout(async () => {
      await this.loadTrombinoscope({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
        searchBy: this.searchBy,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc
      })
    }, 1000);
  }

  async loadTrombinoscope(params?: any) {
    if (!params || params?.reset) {
      this.paginatedListUtilisateursTrombinoscope = new PaginatedList<UtilisateurTrombinoscope>()
    }

    await this.$http.myressif.trombinoscope
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        params?.searchBy ?? "name",
        this.sortBy,
        this.isSortDirDesc
      )
      .then((response: PaginatedList<UtilisateurTrombinoscope>) => {
        this.paginatedListUtilisateursTrombinoscope = response;
        this.loading = false;
      });
  }

  async loadFonctions() {
    await this.$http.myressif.fonctions
      .paginatedList(
        1,
        100,
        false
      )
      .then((response: PaginatedList<Fonction>) => {
        this.listFonctions = response;
      });
  }

  async onDetailsClicked(user: UtilisateurTrombinoscope) {
    this.$router.push({
      name: "edit-utilisateurs",
      params: { id: user.userId! },
    });
  }
}
